<template>
  <div class="relative w-full" :class="{ 'mt-10': containImages, 'mt-5': containVerticalImages }">
    <Swiper
      :modules="[SwiperNavigation]"
      :slides-per-view="auto ? 'auto' : 3.5"
      :space-between="15"
      :slides-offset-before="0"
      :breakpoints="{
        320: {
          slidesPerView: 'auto',
          ...props.midScreenAttributes,
        },
        1024: {
          slidesPerView: 5,
          slidesOffsetBefore: 0,
          ...largeScreenAttributes,
        },
      }"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      class="swiper-container"
    >
      <SwiperSlide v-for="(item, index) in items" :key="index" class="swiper-slide">
        <slot :item="{ ...item, index }" />
      </SwiperSlide>

      <!-- <div v-if="type === 'primary' && swiperInstance && swiperInstance.snapGrid.length > 1" class="flex mt-5">
        <button aria-label="next slide" class="hologram-link text-white hover: mr-8" @click="prevSlide()">
          <svg-icon
            name="slider-arrow"
            width="40"
            height="40"
            class="w-10 h-10 text-transparent hover:text-primary-400"
            :class="{
              'transform scale-x-reverse ': $i18n.locale == 'en',
            }"
          />
        </button>
        <button class="hologram-link text-white mr-7" aria-label="previous slide" @click="nextSlide()">
          <svg-icon
            name="slider-arrow"
            width="40"
            height="40"
            class="w-10 h-10 text-transparent hover:text-primary-400"
            :class="{
              'transform scale-x-reverse': $i18n.locale == 'ar',
            }"
          />
        </button>
      </div> -->
    </Swiper>

    <!-- <template v-if="swiperInstance && swiperInstance.snapGrid.length > 1">
      <button class="swiper-nav swiper-button-prev" aria-label="previous slide" @click="prevSlide">
        <svg-icon
          name="chevron-left"
          class="w-full h-full"
          :class="[$i18n.locale == 'ar' ? 'transform rotate-180' : '']"
        />
      </button>
      <button class="swiper-nav swiper-button-next" aria-label="next slide" @click="nextSlide">
        <svg-icon
          name="chevron-left"
          class="w-full h-full"
          :class="[$i18n.locale == 'ar' ? 'transform rotate-180' : '']"
        />
      </button>
    </template> -->

    <!-- If we need navigation buttons -->
    <!-- <div
      v-if="swiperInstance && isSliderLoaded && arrows"
      class="absolute -top-10 right-5 [ hidden md:flex items-center justify-center space-x-5 ]"
    >
      <button
        class="border rounded-full border-primary-800 w-10 h-10 [ flex items-center justify-center ]"
        aria-label="previous slide"
        @click="prevSlide"
      >
        <svg-icon
          name="chveron-left"
          class="text-primary-1-100 w-4 h-4"
          :class="[$i18n.locale == 'ar' ? 'transform rotate-180' : '']"
        />
      </button>
      <button
        class="border rounded-full border-primary-800 w-10 h-10 [ flex items-center justify-center ]"
        aria-label="next slide"
        @click="nextSlide"
      >
        <svg-icon
          name="chveron-right"
          class="text-primary-1-100 w-4 h-4"
          :class="[$i18n.locale == 'ar' ? 'transform rotate-180' : '']"
        />
      </button>
    </div> -->
  </div>
</template>

<script setup lang="ts">
defineComponent({
  name: 'BulletsSlider',
});
const props = defineProps({
  items: {
    type: Array as PropType<any[]>,
    required: true,
  },
  slidesPerViewMobile: {
    type: Number,
    default: 2.5,
  },
  containImages: {
    type: Boolean,
    default: false,
  },
  containVerticalImages: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'primary',
  },
  selectedItem: {
    type: Object,
    default: null,
  },
  auto: {
    type: Boolean,
    default: false,
  },
  mobileScreenAttributes: {
    type: Object,
    default: () => ({}),
  },
  midScreenAttributes: { type: Object, default: () => ({}) },

  largeScreenAttributes: { type: Object, default: () => ({}) },
  arrows: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="postcss" scoped>
.swiper-container {
  @apply static;
  width: 100%;
  height: 48px;
  &.has-margin-left {
    @apply ml-64;
  }
}

.swiper-wrapper {
  @apply items-center;
}

.swiper-slide {
  width: auto;
  padding-top: 20px;
  margin-top: -20px;

  padding-bottom: 30px;
  margin-bottom: -20px;
}

.swiper-nav {
  width: 35px;
  height: 35px;

  &::after {
    content: none;
  }

  &.swiper-button-next {
    right: -20px;
  }

  &.swiper-button-prev {
    transform: scaleX(-1);
    left: -20px;
  }

  @media (min-width: 1301px) {
    &.swiper-button-next {
      right: -60px;
    }

    &.swiper-button-prev {
      left: -60px;
    }
  }

  &.swiper-button-disabled {
    opacity: 0.5;
  }

  @media (max-width: 1220px) {
    display: none;
  }
}
</style>
